import React, {useState} from 'react';
import {HTMLSelect} from "@blueprintjs/core";
import {getToken} from "auth/token";
import axios from 'axios';


export default (props) => {
  const {sample, onUpdateSuccess} = props;

  const [currentValue, setCurrentValue] = useState(sample.sr_status_final);
  // const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateSRStatusFinal = (id, value) => {
    setLoading(true);
    //onLoading();

    axios({
      method: 'PATCH',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `samples/${id}/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: {
        sr_status_final: value
      }
    })
      .then((resp) => {
      // all good. do nothing
        onUpdateSuccess();
        setCurrentValue(value)
      })
      .catch((e) => {
        // setError('Error saving data')
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <HTMLSelect
        onChange={(e) => {
          updateSRStatusFinal(sample.id, e.currentTarget.value);
        }}
        value={currentValue}
        disabled={loading}
      >
        <option value={''} selected>Choose...</option>
        <option value="balanced">Balanced</option>
        <option value="unbalanced">Unbalanced</option>
        <option value="balanced_normal">Balanced/Normal</option>
        <option value="normal">Normal</option>
        <option value="aneuploid">Aneuploid-Whole Chromosome</option>
        <option value="aneuploid_segmental">Aneuploid-Segmental</option>
        <option disabled value="complex_aneuploid">Complex Aneuploid</option>
        <option value="euploid">Euploid</option>
      </HTMLSelect>
    </div>
  );
}
